<template>
  <transition name="fade">
    <div>
      <dl class="cartflow">
        <dt class="pc">ご注文フロー</dt>
        <dd>
          <div class="pc">
            <ul>
              <li
                :class="{ current: flow == 1, movable: flow > 1 }"
                @click="flowMove(1)"
              >
                基本内容の<br />選択
              </li>
              <li
                :class="{ current: flow == 2, movable: flow > 2 }"
                @click="flowMove(2)"
              >
                詳細内容の<br />選択
              </li>
              <li
                :class="{ current: flow == 3, movable: flow > 3 }"
                @click="flowMove(3)"
              >
                お手紙<br />プレビュー
              </li>
              <li
                :class="{ current: flow == 4, movable: flow > 4 }"
                @click="flowMove(4)"
              >
                ご依頼主様<br />情報の入力
              </li>
              <li
                :class="{ current: flow == 5, movable: flow > 5 }"
                @click="flowMove(5)"
              >
                お届け先<br />情報の入力
              </li>
              <li
                :class="{ current: flow == 6, movable: flow > 6 }"
                @click="flowMove(6)"
              >
                お支払い<br />方法の選択
              </li>
              <li :class="{ current: flow == 7 }">ご注文の<br />最終確認</li>
            </ul>
          </div>
          <div class="sp">
            <ul>
              <li
                :class="{ current: flow == 1, movable: flow > 1 }"
                @click="flowMove(1)"
              >
                {{ flow == 1 ? "1/7" : "" }}
              </li>
              <li
                :class="{ current: flow == 2, movable: flow > 2 }"
                @click="flowMove(2)"
              >
                {{ flow == 2 ? "2/7" : "" }}
              </li>
              <li
                :class="{ current: flow == 3, movable: flow > 3 }"
                @click="flowMove(3)"
              >
                {{ flow == 3 ? "3/7" : "" }}
              </li>
              <li
                :class="{ current: flow == 4, movable: flow > 4 }"
                @click="flowMove(4)"
              >
                {{ flow == 4 ? "4/7" : "" }}
              </li>
              <li
                :class="{ current: flow == 5, movable: flow > 5 }"
                @click="flowMove(5)"
              >
                {{ flow == 5 ? "5/7" : "" }}
              </li>
              <li
                :class="{ current: flow == 6, movable: flow > 6 }"
                @click="flowMove(6)"
              >
                {{ flow == 6 ? "6/7" : "" }}
              </li>
              <li :class="{ current: flow == 7 }">
                {{ flow == 7 ? "7/7" : "" }}
              </li>
            </ul>
          </div>
        </dd>
      </dl>
    </div>
  </transition>
</template>


<style scope lang="scss">
$sp: 680px; // スマホ
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
dl.cartflow {
  background: #f2f2f2;
  padding: 2em;
  border-radius: 0.8em;
  @include sp {
    padding: 0.5em;
    margin-top: 1.5em;
  }

  dt {
    display: block;
    padding-bottom: 0.5em;
    text-align: left;
  }
  dd {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      li {
        background: #9b9b9b;
        margin-right: 0.5em;
        height: 4.5em;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 0.7em;
        color: #fff;
        flex-grow: 1;
        justify-content: center;
        @include sp {
          letter-spacing: 0.2em;
          height: 1em;
        }
        &.current {
          background: #d72e23;
          &::after {
            border-left-color: #d72e23;
          }
        }
      }
      li::after {
        position: absolute;
        right: -0.7em;
        top: 0;
        box-sizing: border-box;
        height: 4.5em;
        display: block;
        content: "";
        border: solid 0.7em #9b9b9b;
        border-right: solid 0px transparent;
        border-top: solid 2.25em transparent;
        border-bottom: solid 2.25em transparent;

        @include sp {
          height: 1em;
          border-right-width: 0em;
          border-top-width: 0.5em;
          border-bottom-width: 0.5em;
        }
      }
      li::before {
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
        height: 4.5em;
        display: block;
        content: "";
        border: solid 0.7em #f2f2f2;
        border-right: solid 0px transparent;
        border-top: solid 2.25em transparent;
        border-bottom: solid 2.25em transparent;
        @include sp {
          height: 1em;
          border-top-width: 0.5em;
          border-bottom-width: 0.5em;
        }
      }
      li:nth-child(1) {
        padding-left: 0.35em;
        z-index: 10;
        &::before {
          display: none;
          border: none;
        }
      }
      li:nth-child(2) {
        z-index: 9;
      }
      li:nth-child(3) {
        z-index: 8;
      }
      li:nth-child(4) {
        z-index: 7;
      }
      li:nth-child(5) {
        z-index: 6;
      }
      li:nth-child(6) {
        z-index: 5;
      }
      li:nth-child(7) {
        z-index: 4;
      }
      li:nth-child(8) {
        z-index: 3;
      }
    }
  }
}
dl.cartflow dd ul li.movable {
  background: #d1d1d1;
  color: rgb(88, 88, 88);
  cursor: pointer;
  &::after {
    border-left-color: #d1d1d1;
  }
  &:hover {
    background: #fff;
  }
  &:hover::after {
    border-left-color: #fff;
  }
}
</style>

<script>
export default {
  data: function () {
    return {
      flowUrls: ["", "option1", "preview", "sender", "deliver", "payment"],
    };
  },
  computed: {},
  watch: {},
  props: ["flow"],
  created: function () {
    //var maxflow=this.getSession("maxflow");
    //if(typeof maxflow=="undefined"||!maxflow)maxflow=0;
    console.log("呼び出されたフロー：", this.flow, "最大フロー:", this.maxflow);
    if (
      (!this.maxflow || this.maxflow - 0 + 1 < this.flow - 0) &&
      this.flow - 0 > 1
    ) {
      //最大フローが無い場合、または最大フロー＋１以上をリクエストされた場合はトップへ戻る
      console.log("フローオーバー");
      window.location.href = "./";
    } else {
      if (this.maxflow < this.flow) {
        console.log("フロー保存");
        //this.setSession("maxflow",this.flow);
        this.$set(this.record, "maxflow", this.flow);
      }
    }
  },
  methods: {
    flowMove: function (num) {
      if (this.flow > num) {
        console.log(num, "/item/" + this.label + "/" + this.flowUrls[num - 1]);
        this.$router.push("./" + this.flowUrls[num - 1]);
      }
    },
  },
};
</script>