import dialogalert from "@/components/dialogalert";
import sideContents from "@/components/sideContents";
import plugin_cartFlow from "@/components/cartFlow";
import fullloader from "@/components/fullloader";
import login from "@/components/login";
import VueScrollTo from 'vue-scrollto';
import Vue from 'vue'
Vue.use(VueScrollTo, {
    /* オプション色々あります。公式サイトから確認できます。 */
    duration: 1000,
    easing: "ease",
    lazy: true
})

export default ({
    components: { dialogalert, sideContents, plugin_cartFlow, login, fullloader },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            console.log("beforeRouteEnter next", "window.checkalways_includes:", window.checkalways_includes, vm.conf);
            // `vm` を通じてコンポーネントインスタンスにアクセス
            vm.fetch("checkalways", { include: window.checkalways_includes }).then((data) => {
                console.log("checked always");
                if (data.init) {
                    eval(data.init)
                }
                vm.conf = data.conf;
            });
        })
        console.log("このコンポーネントを描画するルートが変更されたときに呼び出されます");
        // このコンポーネントを描画するルートが変更されたときに呼び出されますが、
        // このコンポーネントは新しいルートで再利用されます。
        // たとえば、動的な引数 `/foo/:id` を持つルートの場合、`/foo/1` と `/foo/2` の間を移動すると、
        // 同じ `Foo` コンポーネントインスタンスが再利用され、そのときにこのフックが呼び出されます。
        // `this` でコンポーネントインスタンスにアクセスできます。
    },
    beforeRouteLeave(to, from, next) {
        // このコンポーネントを描画するルートが間もなく
        // ナビゲーションから離れていく時に呼ばれます。
        // `this` でのコンポーネントインスタンスへのアクセスができます。
        //他のページに移動したら表示モードを初期化する
        this.viewmode = "";
        next();
    },

    head: {
        title: {
            inner: 'サンタクロースからの手紙',
            complement: 'サンタクロース大使館'
        },
        meta: [
            // { name: 'description', content: 'フィンランドのサンタクロースから届く、すてきなクリスマスプレゼント「サンタクロースからの手紙」お申込み受付中です。心に残る贈り物として是非ご利用ください。', id: 'desc' }, // id to replace intead of create element
        ]

    },
    data: function() {
        return {
            appVersion: window.appVersion,
            fullloading: false,
            isCart: false,
            token: "",
            user: "",
            pass: "",
            item: {},
            options: [],
            //loaded: false,
            loaded_countmax: 999,
            loaded_counted: 0,
            computed: {},


            visible_error: false,
            error_title: "以下をご確認ください",
            error_info: "不備の項目があります。",
            errors_global: {},



            visible_dialog: false,
            dialog_title: "",
            dialog_info: "",
            dialog_bn_ok: "",
            dialog_bn_cancel: "",
            dialog_ok_fn: () => {},

            conf: {}
        }
    },
    computed: {
        loaded: function() {
            return this.loaded_counted >= this.loaded_countmax;
        },
        unit: function() {
            return this.record.fields.items.subfields.length;
        },
        maxflow: function() {
            if (typeof this.record.maxflow == "undefined" || !this.record.maxflow) return 0;
            return this.record.maxflow;
        }
    },
    watch: {
        loaded: function(e, i) {
            if (!i && e === true) {
                this.fullloading = false;
            }
        }
    },
    created: function() {
        //いずれかのコンポーネントでthis.labelに値が入った時点で(それはページのコンポーネント)共通変数にlabelとIDを入れて読み込み
        // console.log("created,",  "1", this.label, "2", this.currentLabel)
        if (this.label) {
            this.currentLabel = this.label;
            this.currentID = this.ID;
            var record = this.getSession("record");
            //console.log("created,", "1", this.label, "2", this.currentLabel, record, this.record)
            if (record) {
                this.record = record;
                //this.$store.state.record = record;
            } else {
                //this.$store.state.record = this.record;
            }
        }
        //console.log("created", this.record)

        if (this.$router.currentRoute.path.indexOf("/item/") !== -1) this.isCart = true;
        //if (typeof this.$parent != "undefined") { this.$parent.middle = 2; }
    },
    methods: {
        //キャンペーンコードを1つ追加
        //将来的に複数の可能性を考えて配列で登録
        setAdcode: function(adcode) {
            window.localStorage.setItem("adcode", JSON.stringify([adcode]));
        },
        //キャンペーンコードを取得
        getAdcode: function() {
            let adcode = window.localStorage.getItem("adcode");
            if (adcode === null || typeof adcode == "undefined") {
                return [];
            } else {
                return JSON.parse(adcode);
            }
        },
        hashScroll: function() {
            //読み込み完了時にハッシュ指定がある場合はアンカーとrefがあるか調べ、ある場合はその場所へスクロールする
            if (window.location.hash) {
                this.$nextTick(() => {
                    let scrID = window.location.hash.replace(/^#/, "")
                    if (typeof this.$refs[scrID] != "undefined") {
                        let clientRect = this.$refs[scrID][0].getBoundingClientRect();
                        let py = window.pageYOffset + clientRect.top;
                        window.scrollTo({
                            top: py,
                            behavior: "smooth"
                        });
                    }
                }, 1000);
            }
        },
        opendialog: function(arg) {
            this.visible_dialog = false;
            this.dialog_title = "ご確認ください";
            this.dialog_info = "";
            this.dialog_ok_fn = function() {};
            this.dialog_bn_ok = "";
            this.dialog_bn_cancel = "";

            if (arg.visible !== false) {
                arg.visible = true;
            }
            if (arg.visible) this.visible_dialog = arg.visible;
            if (arg.title) this.dialog_title = arg.title;
            if (arg.info) this.dialog_info = arg.info;
            if (arg.bn_ok) this.dialog_bn_ok = arg.bn_ok;
            if (arg.bn_cancel) this.dialog_bn_cancel = arg.bn_cancel;
            if (arg.ok_fn) this.dialog_ok_fn = arg.ok_fn;
        },
        loaded_countup: function() {
            this.loaded_counted = this.loaded_counted + 1;
        },
        alpfix: function(value) {
            //console.log(id, this.records)
            if (/[Ａ-Ｚａ-ｚ０-９]/g.test(value)) {
                var rtn = value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
                    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                });
            }
            return rtn;
        },
        kanafix: function(id) {
            //console.log(id, this.records)
            if (/[\u30a1-\u30f6]/g.test(this.records[id])) {
                var rtn = this.records[id].replace(/[\u30a1-\u30f6]/g, function(match) {
                    var chr = match.charCodeAt(0) - 0x60;
                    return String.fromCharCode(chr);
                });
            }
            return rtn;
        },
        get_totalamount: async function() {
            return new Promise((resolve) => {
                var record = JSON.parse(JSON.stringify(this.record));
                this.fetch("load_price", { record: record }).then((data) => {
                    resolve(data)
                });
            })
        },
        change_itemunit: function(unit) {
            console.log("数量変更、", unit)
            var record = this.getSession("record")
            console.log('追加前ストア', JSON.stringify(this.record.fields.items.subfields))
            console.log('追加前2セッション', JSON.stringify(record.fields.items.subfields))
            console.log(record.fields.items.subfields.length, "/unit:", unit)
            var currentitem = record.fields.items.subfields[0];
            var changed = false;
            if (record.fields.items.subfields.length < unit) {
                console.log("------------データ商品数が個数指定より少ないので、データ商品数を追加させる------------")
                var addfileds = {}

                for (var i = record.fields.items.subfields.length; i < unit; i++) {
                    addfileds = {
                        "deliver": {
                            "value": i,
                        },
                        "item": {
                            "value": currentitem.item.value,
                        },
                        "unit": {
                            "value": 1,
                        },
                        "options": {
                            "value": "",
                        }
                    };
                    console.log(i + "番目の追加", addfileds);
                    this.record.fields.items.subfields.push(addfileds);
                    changed = true;

                }


            } else if (record.fields.items.subfields.length > unit) {
                //商品を削除
                this.record.fields.items.subfields.splice(unit);
                //このカートの場合は1商品1配送先なので、配送先も削除
                this.record.fields.mydeliver.subfields.splice(unit);
                changed = true;
            }
            //console.log('追加した直後', JSON.stringify(this.record.fields.items.subfields))
            console.log('追加した直後', this.record.fields.items.subfields[0].options.value)
            if (record.fields.mydeliver.subfields.length < unit) {
                addfileds = {}

                console.log("i2")
                for (var i2 = record.fields.mydeliver.subfields.length; i2 < unit; i2++) {
                    addfileds = {
                        "type": {
                            "value": "",
                        },
                        "status": {
                            "value": "",
                        },
                        "date": {
                            "value": "",
                        },
                        "code": {
                            "value": "",
                        },
                        "id": {
                            "value": "",
                        },
                        "flag": {
                            "value": [],
                        },
                        "sei": {
                            "value": "",
                        },
                        "mei": {
                            "value": "",
                        },
                        "sei_kana": {
                            "value": "",
                        },
                        "mei_kana": {
                            "value": "",
                        },
                        "tel": {
                            "value": "",
                        },
                        "zip": {
                            "value": "",
                        },
                        "state": {
                            "value": "",
                        },
                        "city": {
                            "value": "",
                        },
                        "street": {
                            "value": "",
                        },
                        "building": {
                            "value": "",
                        },
                        "other": {
                            "value": "",
                        }
                    };
                    this.record.fields.mydeliver.subfields.push(addfileds);
                    changed = true;

                }


            }
            //if (changed) this.setSession("record", this.record, true);
            //this.setSession("unit", unit, true);
            console.log("個数変更", this.record)
        },
        options_filter: function(typetag) {
            let typetags = typetag.split(",");
            var rtn = [];
            //console.log("-------------------------------",Object.keys(this.$store.state.options))
            for (var i = 0; i < this.options.length; i++) {
                for (var i2 = 0; i2 < typetags.length; i2++) {
                    if (this.options[i].fields.typetag.value.split(",").indexOf(typetags[i2]) !== -1) {
                        rtn.push(this.options[i]);
                        break;
                    }
                }
            }
            return rtn;
        },
        htmlformat: function(str) {
            str = str.replace(/(\r?\n)*<br\s*\/?>(\r?\n)*/g, "<br>");
            str = str.replace(/\r?\n/g, "<br>");
            return str;
        },
        mergeCommon: function(data) {
            let common = this.common;
            let common1 = {...common, ...data };
            //this.$store.commit('updateCommon', common);
            // this.$set(this.$store.state, "common_stored", common)
            this.common = common1;
        },

        get_itemoption_byid: async function(ID) {
            return new Promise((resolve, reject) => {


                this.fetch("load_item", { ID: ID, mode: "alwaysall" }).then((data) => {
                    if (data.error) {
                        console.log(data.error)
                        reject();
                        return;
                    }
                    //console.log(data);
                    /*
                    var itemoptions = this.getSession("itemoptions");
                    if (typeof itemoptions != "undefined") {
                        itemoptions[itemlabel] = data;
                        this.setSession("itemoptions", itemoptions);
                    }
                    */

                    this.item = data.item;
                    this.options = data.options;
                    //console.log("取得したアイテム定義：", data.item)
                    //console.log("取得したオプション定義：", data.options)
                    resolve();
                    return;
                });


            });
        },

        get_itemoption: async function(itemlabel) {
            return new Promise((resolve, reject) => {

                var options = this.getSession("itemoptions");
                let adcode = this.getAdcode().join(',');
                console.log("上書き前", this.options)

                if (typeof options != "undefined" && typeof options[itemlabel] != "undefined") {
                    this.item = options[itemlabel].item;
                    this.options = options[itemlabel].options;
                    //console.log("保存されたオプション定義：", options[itemlabel])
                    resolve();
                    return;
                } else {
                    this.fetch("load_item", { label: itemlabel, adcode: adcode, record: this.record, mode: "alwaysall" }).then((data) => {
                        if (data.error) {
                            console.log(data.error)
                            reject();
                            return;
                        }
                        //console.log(data);
                        var itemoptions = this.getSession("itemoptions");
                        if (typeof itemoptions != "undefined") {
                            itemoptions[itemlabel] = data;
                            this.setSession("itemoptions", itemoptions);
                        }

                        this.item = data.item;
                        this.options = data.options;
                        //console.log("取得したアイテム定義：", data.item)
                        //console.log("取得したオプション定義：", data.options)
                        resolve();
                        return;
                    });
                }
                console.log("上書き語", this.options)

            });
        },
        get_tagged_values: function(values, options) {
            //console.log("value.values1", values)
            var use_options = ['letter_target', 'letter_type', 'letter_lang', 'letter_callname', 'letter_msg', 'letter_sender', 'letternumber'];
            var opt = [];
            for (var i = 0; i < use_options.length; i++) {
                let matched = false;
                let u = use_options[i];
                if (!options) options = this.options;
                for (var i2 = 0; i2 < options.length; i2++) {
                    let o = options[i2];
                    if (o.fields.typetag.value.split(",").indexOf(u) != -1) {
                        for (var i3 = 0; i3 < values.length; i3++) {
                            let value = values[i3];
                            if (value.ID == o.ID) {
                                //console.log("value.values", i3, value.values)
                                if (value.values.length) {
                                    opt[u] = value.values[0].value;
                                    matched = true;
                                }
                            }
                        }
                        break;
                    }
                }
                if (!matched) {
                    opt[u] = "";
                }
            }
            return opt
        },
        myDebug: function(senderrormsg) {

            let debug = "no";
            //エラーメッセージがある場合送信
            if (senderrormsg) {
                if (debug == "no") {
                    this.fetch("errordebug", {
                        errormsg: senderrormsg,
                        data: window.localStorage.getItem("cartSession")
                    }, true).then(() => {
                        //alert("エラーが発生しました。動作が正常に行われなかった可能性があります。\nこのエラーは匿名で管理者に通知されました。\n数日たっても同じ箇所でエラーが続く場合は、お手数ですが管理者にご連絡ください。");
                    });
                } else {}
            }
        },
        fetch: async function(url, data, nocatch) {
            var token = this.getSession("token");
            if (token) data.token = token;
            data.version = this.appVersion;
            //return new Promise((resolve, reject) => 
            return fetch(API_URL + url, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    //'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data),
            }).then(response => response.json().catch(err => {
                if (nocatch) return;
                let errtxt = err.toString();
                try { errtxt += "\n\n-------------\n" + this.$router.currentRoute.path } catch (e) {}
                this.myDebug(errtxt)
            }));

        },
        gethtml: async function(url, data) {
            var token = this.getSession("token");
            if (token) data.token = token;
            //return new Promise((resolve, reject) => 
            return fetch(API_URL + url, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data),
            }).then(response => response.text());

        },
        getSession: function(name) {
            let namefied = name;
            if (name == 'record') {
                //暫定、決め打ちでsanta2021の時だけ空に
                console.log("this.currentLabel-------", this.currentLabel)
                if (!this.currentLabel) return;
                let label = this.currentLabel;
                if (label == "santa2021") label = "";
                if (this.currentID) label += "_update_" + this.currentID;
                if (label) namefied = name + "_" + label;
                console.log(namefied, "getSession時this.currentLabel", this.currentLabel, "this.label", this.label, "this.ID", this.ID, "this.currentID", this.currentID)

            }
            //console.log("セッション", name)
            // if (!this.$store.state[name] || flg_localStorage) {
            var storageData = this.get_localStorage(namefied);
            if (storageData) {
                //console.log("半永続化セッションから復帰", name, storageData);
                //this.$set(this.$store.state, name, storageData);
                //console.log(Object.keys(storageData));
                //タイプがrecordの場合は、adcodeを付与する
                if (name == 'record') {

                    let adcode = this.getAdcode().join(',');
                    storageData.fields.adcode.value = adcode;
                }
                return storageData;
            }
            // }
            //return this.$store.state[name];
        },
        //推奨されない方法だが、これで共通変数を実現する
        setSession: function(name, val) {
            let namefied = name;
            if (name == 'record') {
                if (!this.currentLabel) return;
                //暫定、決め打ちでsanta2021の時だけ空に
                let label = this.currentLabel;
                if (label == "santa2021") label = "";
                if (this.currentID) label += "_update_" + this.currentID;
                if (label) namefied = name + "_" + label;
                //if (!label) return;
                console.log("保存時", namefied, this.record.fields.sei.value)
            }
            // this.$set(this.$store.state, name, val);
            // if (flg_localStorage) {
            //console.log("セッション半永続化", namefied)
            this.save_localStorage(namefied, val)
                // }
            return true;
        },
        clearAllCart: function() {
            if (window.confirm("カートの内容を全てクリアしてもよろしいですか？\n※元に戻すことはできません。ご注意ください。")) {

                this.clearAllCart_exec();

                //window.localStorage.removeItem("cartSession");
                window.location.href = "/";
            }
        },
        clearAllCart_exec: function() {

            var cartSession = window.localStorage.getItem("cartSession");
            if (cartSession === null || typeof cartSession == "undefined") {
                //none
            } else {
                cartSession = JSON.parse(cartSession);
                //残す値
                let ob = ["logined", "token"];
                for (var i in cartSession) {
                    //recordだけ削除してしまうと依頼者情報のページが空白になってしまうので一旦すべて削除
                    if (ob.indexOf(i) == -1) {
                        delete cartSession[i];
                    }
                }
                window.localStorage.setItem("cartSession", JSON.stringify(cartSession));
            }
            //adcodeも削除
            window.localStorage.removeItem("adcode");
        },
        save_localStorage: function(name, val) {
            var cartSession = window.localStorage.getItem("cartSession");
            if (cartSession === null || typeof cartSession == "undefined") {
                cartSession = {};
            } else {
                cartSession = JSON.parse(cartSession)
            }
            cartSession[name] = val;
            window.localStorage.setItem("cartSession", JSON.stringify(cartSession));
        },
        get_localStorage: function(name) {
            var cartSession = window.localStorage.getItem("cartSession");
            if (cartSession === null || typeof cartSession == "undefined") {
                cartSession = {};
            } else {
                cartSession = JSON.parse(cartSession)
            }
            var rtn = cartSession[name];
            if (typeof rtn == "undefined" || rtn === null) rtn = "";
            return rtn;
        },
        getMyData: function() {
            return new Promise((resolve, reject) => {
                var token = this.getSession("token");
                if (!token) return;
                var data = { token: token, mode: "getall" };
                this.fetch("login", data).then(data => {
                    if (data.errormsg) {
                        reject(data);
                    } else {
                        resolve(data)
                    }
                    return;
                })
            });
        },
        login: function(mode) {
            return new Promise((resolve, reject) => {
                var token = this.getSession("token");
                if (token) {
                    console.log("ログイン済み")
                        //resolve({ msg: "ログイン済み" });
                        //return;
                }
                //if (!token) token = this.get_localStorage("token");

                var data = { user: this.user, pass: this.pass, token: token, mode: mode };
                this.fetch("login", data).then(data => {
                    if (data.errormsg) {
                        this.errormsg = data.errormsg;

                        this.setSession('token', "", true)
                        this.setSession('logined', false);
                        this.token = "";
                        this.logined = false;

                        this.mergeCommon(data);
                        reject(data);

                    } else {
                        this.errormsg = "";
                        //セッションストレージにも保存する
                        this.setSession('token', data.token, true)
                        this.setSession('logined', true);
                        this.token = data.token;
                        this.logined = true;
                        this.mergeCommon(data);
                        resolve(data)
                    }
                    return;
                })
            });
        },

        // 現在の日付
        fetchCurrentDate: function(format, datetext) {
            //console.log("datevalue", datetext);
            var datevalue;
            if (typeof datetext == "date") datevalue = datetext;
            if (typeof datetext == "string") datevalue = new Date(datetext);
            if (typeof datetext == "undefined") datevalue = new Date();
            if (!format) {
                format = 'YYYY-MM-DD hh:mm:ss';
            }
            //文字列からの日付化に失敗したとき
            if (Number.isNaN(datevalue.getFullYear())) {
                var datearr = datetext.match(/^([0-9]+)-([0-9]+)-([0-9]+)\s([0-9]+):([0-9]+):([0-9]+).*$/);
                datevalue = new Date(datearr[1] - 0, datearr[2] - 1, datearr[3] - 0, datearr[4] - 0, datearr[5] - 0, datearr[6] - 0);
                //console.log(datearr, datevalue, datearr[1] - 0, datearr[2] - 1, datearr[3] - 0, datearr[4] - 0, datearr[5] - 0, datearr[6] - 0);
            }
            var youbi = ["日", "月", "火", "水", "木", "金", "土"];
            format = format.replace(/YYYY/g, datevalue.getFullYear());
            format = format.replace(/MM/g, ('0' + (datevalue.getMonth() + 1)).slice(-2));
            format = format.replace(/DD/g, ('0' + datevalue.getDate()).slice(-2));
            format = format.replace(/M/g, ((datevalue.getMonth() + 1)));
            format = format.replace(/D/g, (datevalue.getDate()));
            format = format.replace(/hh/g, ('0' + datevalue.getHours()).slice(-2));
            format = format.replace(/mm/g, ('0' + datevalue.getMinutes()).slice(-2));
            format = format.replace(/ss/g, ('0' + datevalue.getSeconds()).slice(-2));
            format = format.replace(/W/g, youbi[datevalue.getDay()]);


            return format;
        },
        logout: function() {
            this.setSession('token', "", true)
            this.setSession('logined', false);

            this.token = "";
            this.logined = false;
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
});