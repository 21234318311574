<template>
  <div class="join_comp" v-if="loaded">
    <div v-if="logined">
      <p>会員ログイン済みです。</p> 
      <button @click="bn_logout">ログアウト</button>
    </div>
    <div v-else>
      <h2>会員ログイン</h2>

      <dl><dt>メールアドレス</dt>
      <dd><input type="text" v-model="user" /></dd></dl>
      <dl><dt>パスワード</dt>
      <dd><input type="password" v-model="pass" /></dd></dl>
      <p class="errormsg" v-if="errormsg">{{ errormsg }}</p>
      <button @click="bn_login">ログイン</button>
      <br>
      <lostpass/>
    </div>
  </div>
</template>

<script>
//import { mapState, mapMutations, mapActions } from "vuex";
//新規ログインの機能はコンポーネント化してポップアップで会員ログインできるようにする
</script>
<script>
import lostpass from "@/components/lostpass";
export default {
  components:{lostpass},
  data: function () {
    return {
      errormsg: "",
      lostpass:""
    };
  },
  props:["value","to"],
  created:function(){
    this.loaded_countmax=1;
    var token = this.getSession("token");
    console.log("token",token,"to:",this.to)
     if (token) {
      this.login()
        .then(()=>{
          this.loaded_countup();
          this.$emit('input', true);
        })
        .catch((data) => {
          this.loaded_countup();
          this.$emit('input', false);
          
          console.log("失敗", data)});
     }else{
          this.loaded_countup();
     }

  },
  watch:{
    //token:function(){console.log("token changed")}
  },
  computed: {
    //...mapState(["token"]),
  },
  methods: {
    //...mapMutations(["_state"]),
   // ...mapActions([]),
    bn_logout: function () {
          this.$emit('input', false);
       this.logout();
    },
    bn_login: function () {
      this.login()
        .then(()=>{
          this.$emit('input', true);
          if(this.to){
            this.$router.push(this.to);
          }
        })
        .catch((data) => console.log("失敗", data));
      return;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.join_comp{
    padding: 2em;
    display: flex;
    justify-content: center;
  }
  h2{
    text-align: center;
    font-weight: normal;
  }
  dl{
    dt{
      font-size: .8em;
    }
    dd{
      margin-bottom: .5em;
    }
  }
.errormsg {
  background: #f3f2c2;
  border-radius: 0.5em;
  padding: 0.5em 0;
}
</style>
