import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from "vue-gtag";
Vue.use(VueRouter)


import VueHead from 'vue-head'
Vue.use(VueHead)


import Home from '../views/Home.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
},
{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import('../views/About.vue')
},
{
    path: '/item/:label/',
    name: 'santa',
    component: () =>
        import('../views/item/index.vue')
},
{
    path: '/item/:label/option1',
    name: 'santa_option1',
    component: () =>
        import('../views/item/option1.vue')
},
{
    path: '/item/:label/option2',
    name: 'santa_option2',
    component: () =>
        import('../views/item/option2.vue')
},
{
    path: '/item/:label/preview',
    name: 'santa_preview',
    component: () =>
        import('../views/item/preview.vue')
},
{
    path: '/item/:label/sender',
    name: 'santa_sender',
    component: () =>
        import('../views/item/sender.vue')
},
{
    path: '/item/:label/deliver',
    name: 'santa_deliver',
    component: () =>
        import('../views/item/deliver.vue')
},
{
    path: '/item/:label/payment',
    name: 'santa_payment',
    component: () =>
        import('../views/item/payment.vue')
},
{
    path: '/item/:label/confirm',
    name: 'santa_confirm',
    component: () =>
        import('../views/item/confirm.vue')
},
{
    path: '/item/:label/complete',
    name: 'santa_complete',
    component: () =>
        import('../views/item/complete.vue')
},






{
    path: '/item/:label/update/:ID',
    name: 'santa_update',
    component: () =>
        import('../views/item/index.vue')
},
{
    path: '/item/:label/option1/update/:ID',
    name: 'santa_option1_update',
    component: () =>
        import('../views/item/option1.vue')
},
{
    path: '/item/:label/option2/update/:ID',
    name: 'santa_option2_update',
    component: () =>
        import('../views/item/option2.vue')
},
{
    path: '/item/:label/preview/update/:ID',
    name: 'santa_preview_update',
    component: () =>
        import('../views/item/preview.vue')
},
{
    path: '/item/:label/sender/update/:ID',
    name: 'santa_sender_update',
    component: () =>
        import('../views/item/sender.vue')
},
{
    path: '/item/:label/deliver/update/:ID',
    name: 'santa_deliver_update',
    component: () =>
        import('../views/item/deliver.vue')
},
{
    path: '/item/:label/payment/update/:ID',
    name: 'santa_payment_update',
    component: () =>
        import('../views/item/payment.vue')
},
{
    path: '/item/:label/confirm/update/:ID',
    name: 'santa_confirm_update',
    component: () =>
        import('../views/item/confirm.vue')
},
{
    path: '/item/:label/complete/update/:ID',
    name: 'santa_complete_update',
    component: () =>
        import('../views/item/complete.vue')
},



{
    path: '/status/:ordertoken',
    name: 'status2',
    component: () =>
        import('../views/item/status.vue')
},

{
    path: '/cfm/:label/status/:ordertoken',
    name: 'cfm2',
    component: () =>
        import('../views/cfm/status.vue')
},

{
    path: '/item/:label/status/:ordertoken',
    name: 'santa_status',
    component: () =>
        import('../views/item/status.vue')
},
{
    path: '/informations/',
    name: 'informations',
    component: () =>
        import('../views/informations/index.vue')
},
{
    path: '/informations/news',
    name: 'informations_news',
    component: () =>
        import('../views/informations/news.vue')
},
{
    path: '/informations/about',
    name: 'informations_about',
    component: () =>
        import('../views/informations/about.vue')
},
{
    path: '/informations/contact',
    name: 'informations_contact',
    component: () =>
        import('../views/informations/contact.vue'),
    /*children: [
      {
        // /user/:id/profile がマッチした時に
        // UserProfile は User の <router-view> 内部で描画されます
        path: 'profile',
        import ('../views/informations/contact/contact.vue'),
      },
      {
        // /user/:id/posts がマッチした時に
        // UserPosts は User の <router-view> 内部で描画されます
        path: 'posts',
        component: UserPosts
      }
    ]*/
},
{
    path: '/informations/guide',
    name: 'informations_guide',
    component: () =>
        import('../views/informations/guide.vue')
},
{
    path: '/informations/history',
    name: 'informations_history',
    component: () =>
        import('../views/informations/history.vue')
},
{
    path: '/informations/qa',
    name: 'informations_qa',
    component: () =>
        import('../views/informations/qa.vue')
},


/*
                            {
                                path: '/informations/qa_preview',
                                name: 'informations_qa_preview',
                                component: () =>
                                    import ('../views/informations/qa_preview.vue')
                            },*/
{
    path: '/informations/safety',
    name: 'informations_safety',
    component: () =>
        import('../views/informations/safety.vue')
},
{
    path: '/informations/meetsanta',
    name: 'informations_meetsanta',
    component: () =>
        import('../views/informations/meetsanta.vue')
},
{
    path: '/mypage/',
    name: 'mypage',
    component: () =>
        import('../views/mypage/index.vue')
},
{
    path: '/mypage/deliver',
    name: 'mypage_deliver',
    component: () =>
        import('../views/mypage/deliver.vue')
},
{
    path: '/mypage/order_history',
    name: 'mypage_order_history',
    component: () =>
        import('../views/mypage/order_history.vue')
},
{
    path: '/mypage/profile',
    name: 'mypage_profile',
    component: () =>
        import('../views/mypage/profile.vue')
},
{
    path: '/mypage/update/:ID',
    name: 'mypage_update',
    component: () =>
        import('../views/mypage/order_update.vue')
},

{
    path: '/join',
    name: 'join',
    component: () =>
        import('../views/join.vue')
},
{
    path: '/status',
    name: 'status',
    component: () =>
        import('../views/status.vue')
},
{
    path: '/cfm',
    name: 'cfm',
    props: { hidebar: true },
    component: () =>
        import('../views/cfm/index.vue')
},
{
    path: '/informations/terms',
    name: 'term',
    component: () =>
        import('../views/informations/terms.vue')
}, {
    path: '/informations/privacy',
    name: 'privacy',
    component: () =>
        import('../views/informations/privacy.vue')
}, {
    path: '/informations/company',
    name: 'company',
    component: () =>
        import('../views/informations/company.vue')
}, {
    path: '/informations/law',
    name: 'law',
    component: () =>
        import('../views/informations/law.vue')
}, {
    path: '/informations/mailmag',
    name: 'mailmag',
    component: () =>
        import('../views/informations/mailmag.vue')
}, {
    path: '/informations/wholesele',
    name: 'wholesele',
    component: () =>
        import('../views/informations/wholesele.vue')
}
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

Vue.use(VueGtag, {
    config: { id: "UA-42386303-1" }
}, router);


router.afterEach((to, from, next) => {
    //キャンペーンコードの記録
    if (typeof to.query.adcode != "undefined") {
        console.log("キャンペーン" + to.query.adcode);
        //将来的に複数の可能性を考えて配列で登録
        window.localStorage.setItem("adcode", JSON.stringify([to.query.adcode]));
    }
})

export default router