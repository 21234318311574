<template>
  <div class="home">
    <div class="contentFrame">
      <div class="leftSide">
        <div class="informationbox">
          <h2>ご案内</h2>
          <div class="sentent">
            2024年のサンタクロースからの手紙のお申込受付を開始させて頂きました。<br />
            大切な方々への心温まるクリスマスプレゼントとして是非ご利用ください。
            <!--      -->

            <!-- サンタクロースからの手紙をご利用頂きありがとうございます。<br />
            本年度2023年のサンタクロースからの手紙のお申込受付を終了させて頂きました。-->
            <!--       
            サンタクロースからの手紙をご利用頂きありがとうございます。<br>
準備も整い、2023年のサンタクロースからの手紙のお申込受付を開始させて頂きました。<br>
更に便利にわかりやすくご利用頂けますように改良改善を行い、新たなサービスも追加いたしました。昨年来のコロナ禍、なかなかお会いできない大切な方々への心温まるクリスマスプレゼントとして是非ご利用ください。
-->
          </div>
        </div>

        <div class="middlebncontents sp">
          <ul>
            <li>
              <router-link
                @click.native="scrollToTop"
                to="/item/santa2024_early/"
                ><img src="../assets/images/bnr_submit.png" alt=""
              /></router-link>
            </li>
            <li class="green">
              <router-link @click.native="scrollToTop" to="/informations/guide"
                >ご利用ガイド</router-link
              >
            </li>
          </ul>
        </div>

        <div class="secondcontent">
          <h2>サンタクロースからの手紙って？</h2>
          <div class="sentent backimg1">
            サンタクロースの故郷、フィンランド・旧ラップランド州公認のメッセージをクリスマスまでにお届けいたします。<br />
            <br />
            ●大切な方のお名前が手紙に記載されます。<br />
            例：あいなちゃんへ<br />
            <br />
            ●フィンランドの人気画家によるサンタクロースの温かいイラストが描かれています。<br />
            <br />
            ●日本語・英語の言語の選択が出来、手紙の内容は、幼児向け（ひらがな）、お子様向け（簡単な漢字）、一般向けと選べます。<br />
            例：３歳のお子さん⇒幼児向け、８歳のお子さん⇒お子様向け など<br />
            <br />
            ●ご依頼主様から「６０文字以内のメッセージ」も記載できます。<br />
            例：「あいなちゃん、来年は幼稚園卒業だね・・・メリークリスマス！」<br />
            <br />
            ●クリスマス切手型シールには公認サンタクロース監修の消印マークが押印されます。<br />
            <br />
            ●毎年お楽しみの「スモールギフト（おまけカード）」もついてきます。<br />
            <br />
            ●世界に1通だけのサンタクロースからの手紙です。

            <p class="bn_link_detail">
              <router-link @click.native="scrollToTop" to="/informations/about"
                >詳しくはこちら≫</router-link
              >
            </p>
          </div>
        </div>

        <div class="secondcontent" v-if="common.news && common.news.length">
          <h2>最新情報</h2>
          <div class="sentent">
            <ul>
              <li v-for="(news, n) in common.news" :key="n">
                {{ news.page_title }} [{{
                  fetchCurrentDate("YYYY.MM.DD", news.page_open)
                }}]<br />

                <p class="newsinfo" v-html="news.fields.content.value"></p>
              </li>
            </ul>
          </div>
        </div>

        <div class="thirdcontent sp">
          <h2>安心・安全について</h2>
          <div class="sentent safety">
            <router-link @click.native="scrollToTop" to="/informations/safety"
              ><img src="../assets/images/bn_safety.png" alt=""
            /></router-link>
            <div>
              サンタクロース大使館では、状況の確認ができるヤマト運輸と日本郵政の「クロネコゆうパケット」で発送しています。
            </div>
          </div>
        </div>

        <div class="thirdcontent">
          <h2>サンタクロースからの手紙</h2>
          <div class="sentent backimg2">
            <div><img src="../assets/images/ilst_topletter.png" alt="" /></div>
          </div>
        </div>

        <div class="thirdcontent sp">
          <h2>過去の封筒・便箋ギャラリー</h2>
          <div class="sentent letters">
            <div>
              毎年変わる封筒・便箋のデザイン！
              コレクション頂いている方も沢山いらっしゃいます♪
            </div>
            <div><img src="../assets/images/ilst_letter2020.png" alt="" /></div>
            <div><img src="../assets/images/ilst_letter2019.png" alt="" /></div>
            <div class="morebn">
              <router-link
                @click.native="scrollToTop"
                to="/informations/history"
                >もっと見る</router-link
              >
            </div>
          </div>
        </div>
        <!--
        <div class="thirdcontent sp">
          <h2>SNS</h2>
          <div class="sentent">
            <div class="pdt1 pdb1">
              SNSを開設致しました。是非フォローをお願い致します！
              <ul class="socialbns">
                <li><a href=""><img src="../assets/images/icon_fb.png" alt=""></a></li>
                <li><a href=""><img src="../assets/images/icon_tw.png" alt=""></a></li>
              </ul>
            </div>
          </div>
        </div>
-->
        <div class="thirdcontent sp">
          <h2>お問合わせ</h2>
          <div class="sentent pdt1 pdb1">
            <router-link @click.native="scrollToTop" to="/informations/qa"
              ><img src="../assets/images/bn_contact.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$sp: 480px; // スマホ
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.middlebncontents {
  img {
    max-width: 100%;
  }
}
.secondcontent {
  .bn_link_detail {
    margin-top: 1.5em;
    font-size: 1.3em;
    a {
      text-decoration: none;
      color: #2c2c2c;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    list-style: none;
    li {
      font-weight: bold;
      color: #333333;
      padding-bottom: 0.8em;
      &:last-child {
        border-bottom: none;
      }
    }
    .newsinfo {
      color: #161616;
      font-weight: normal;
      margin-top: 0.6em;
      border: solid 1px rgb(230, 230, 230);
      padding: 0.8em;
      font-size: 0.8em;
      line-height: 1.7;
    }
  }
}
.green a {
  color: #202020;
  text-decoration: none;
  font-size: 0.8em;
  @include sp {
    color: #fff;
  }
}
.green {
  font-size: 1.3em;
  color: #162345;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8em 0;
  //border: solid 1px #ccc;
  border-radius: 0.4em;
}
.green::before,
.green::after {
  content: "";
  display: inline-block;
  background: url("../assets/images/icon_green.png") no-repeat center / contain;
  width: 1.5em;
  height: 1.5em;
}
</style>
<script>
export default {
  head: {
    title: {
      inner: "サンタクロースからの手紙",
      complement: "サンタクロース大使館",
    },
  },
  data: function () {
    return {};
  },
  created: function () {
    window.checkalways_includes = "statuses";
    this.$nextTick(() => {
      this.$parent.middle = 1;
    });
  },
};
</script>
