<template>

  <transition name="fade">
<div class="custumdialog noselect" v-if="visible||value">
    <dl>
        <dd class="dialogbody">
            <div v-if="bigtitle" class="dialogbigtitle" v-html="bigtitle"></div>
            <div v-if="title" class="dialogtitle" v-html="title"></div>
            <div v-if="info" class="dialoginfo" v-html="info"></div></dd>
        <dd class="dialogbns notselect">
            <ul>
                <li v-if="button_ok" class="primary" @click="fn1">{{button_ok}}</li>
                <li v-if="button_close" class="primary" @click="fn2">{{button_close}}</li>
                <li v-if="button_cancel" class="secondary" @click="fn3">{{button_cancel}}</li>
            </ul>
        </dd>
    </dl>
</div>
  </transition>

</template>
<script>
/*
インポート例：
<dialogalert v-model:visible="visible" :bigtitle="大きいタイトル" title="アラートタイトル" info="説明文" button_close="閉じるボタン" button_cancel="キャンセルボタン" button_ok="OKボタン" :ok_fn="function" :cancel_fn="function" />
*/
  export default {
    props:[
      "visible",
      "value",
      "title",
      "bigtitle",
      "info",
      "button_ok",
      "button_close",
      "button_cancel",
      "ok_fn",
      "cancel_fn"
    ],
    created:function(){
      //alert(1)
    },
    methods:{
        fn1:function(){
            if(typeof this.ok_fn=="function"){
                this.ok_fn();
            }
            //this.$emit('update:visible', 0);
            this.$emit('input', 0);
        },
        fn2:function(){
            if(typeof this.cancel_fn=="function"){
                this.cancel_fn();
            }
            //this.$emit('update:visible', 0);
            this.$emit('input', 0);
        },
        fn3:function(){
            //this.$emit('update:visible', 0);
            this.$emit('input', 0);
        }
    }
  }
</script>
<style scoped>
.notselect{
   -ms-user-select: none; /* IE 10+ */
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   user-select: none;
}
.custumdialog{
    background: rgba(0, 0, 0, 0.767);
    position: fixed;
    left: 0;top: 0;right: 0;bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.custumdialog dl{
    border-radius: 1em;
    background: #fff;
    width: 80%;
    max-width: 600px;
    overflow: hidden;
}

.custumdialog .dialogbody{
    padding-top: 2.5em;
    padding-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.custumdialog .dialogbody .dialogtitle{
    color: #097300;
    line-height: 1.7;
    font-size: 1.17rem;
    width: 85%;
}
.custumdialog .dialogbody .dialogbigtitle{
    color: #2a2a2a;
    line-height: 1.7;
    font-size: 1.06rem;
    width: 85%;
    text-align: center;
}

.custumdialog .dialogbody input.fullarea{
    margin-top: .5em;
    width: 85%;
    font-size: 1.41rem;
}

.custumdialog .dialogbody .dialoginfo{
    color: #000000;
    font-size: 0.82rem;
    line-height: 1.7;
    margin-top: 1em;
    width: 85%;
    padding-left: 1em;
}
.custumdialog .dialogbody .dialoginfo::before{
    /*content: "※";
    margin-left: -1em;*/
}
.custumdialog .dialogbns{
}
.custumdialog .dialogbns ul{

}
.custumdialog .dialogbns ul li{
    border-top: solid 1px #000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.64rem;
    padding: .5em 0;
    cursor: pointer;
}

.custumdialog .dialogbns ul li:active{
    background: #cfe6d1;
}
.custumdialog .dialogbns ul li.primary{
    color: #008B0B;
}
.custumdialog .dialogbns ul li.secondary{
    color: #969696;
}

.custumdialog .dialogbns ul li i{
    font-size: .7em;
    text-decoration: none;
    font-style: normal;
    padding-left: .2em;
}
</style>