import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        record_stored: {
            "ID": "",
            "page_title": "サンタクロースからの手紙",
            "page_type": "order",
            "fields": {
                "prices": {
                    "value": ""
                },
                "orderno": {
                    "value": ""
                },
                "order_status": {
                    "value": ""
                },
                "flag": {
                    "value": ""
                },
                "campain": {
                    "value": ""
                },
                "payment_status": {
                    "value": ""
                },
                "payment_type": {
                    "value": ""
                },
                "payment_info": {
                    "value": ""
                },
                "info_customer": {
                    "value": ""
                },
                "info_memo": {
                    "value": ""
                },
                "adcode": {
                    "value": ""
                },
                "myuser": {
                    "value": ""
                },
                "copy_myuser": {
                    "value": ""
                },
                "sei": {
                    "value": ""
                },
                "mei": {
                    "value": ""
                },
                "sei_kana": {
                    "value": ""
                },
                "mei_kana": {
                    "value": ""
                },
                "zip": {
                    "value": ""
                },
                "state": {
                    "value": ""
                },
                "city": {
                    "value": ""
                },
                "street": {
                    "value": ""
                },
                "building": {
                    "value": ""
                },
                "other": {
                    "value": ""
                },
                "address": {
                    "value": ""
                },
                "tel": {
                    "value": ""
                },
                "mydeliver": {
                    "value": null,
                    "subfields": [{
                        "savedeliver": {
                            "value": true,
                        },
                        "addressmode": {
                            "value": "create",
                        },
                        "type": {
                            "value": "",
                        },
                        "status": {
                            "value": "",
                        },
                        "date": {
                            "value": "",
                        },
                        "time": {
                            "value": "",
                        },
                        "code": {
                            "value": "",
                        },
                        "id": {
                            "value": "",
                        },
                        "flag": {
                            "value": [],
                        },
                        "sei": {
                            "value": "",
                        },
                        "mei": {
                            "value": "",
                        },
                        "sei_kana": {
                            "value": "",
                        },
                        "mei_kana": {
                            "value": "",
                        },
                        "tel": {
                            "value": "",
                        },
                        "zip": {
                            "value": "",
                        },
                        "state": {
                            "value": "",
                        },
                        "city": {
                            "value": "",
                        },
                        "street": {
                            "value": "",
                        },
                        "building": {
                            "value": "",
                        },
                        "other": {
                            "value": "",
                        },
                        "title": {
                            "value": ""
                        }
                    }, ]
                },
                "items": {
                    "subfields": [{
                        "deliver": {
                            "value": 0,
                        },
                        "item": {
                            "value": "",
                        },
                        "unit": {
                            "value": 1,
                        },
                        "options": {
                            "value": "",
                        }
                    }, ]
                },
                "payment_discount": {
                    "value": ""
                },
                "payment_customdeliver": {
                    "value": ""
                },
                "payment_fee": {
                    "value": ""
                }
            },
        },
        unit_stored: 1,
        label_stored: "",
        ID_stored: "",
        viewmode_stored: "",
        //options: {},
        itemoptions: {},
        token: "",
        logined_stored: false,
        common_stored: {}
        /*fields: {
            sei: { value: "", tag: ["join"] },
            mei: { value: "", tag: ["join"] },
            sei_kana: { value: "", tag: ["join"] },
            mei_kana: { value: "", tag: ["join"] },
            email: { value: "", tag: ["join"] },
            userpass: { value: "", tag: ["join"] },
            role: { value: "", tag: ["join"] },
            zip: { value: "", tag: ["join"] },
            state: { value: "", tag: ["join"] },
            city: { value: "", tag: ["join"] },
            street: { value: "", tag: ["join"] },
            building: { value: "", tag: ["join"] },
            other: { value: "", tag: ["join"] },
            address: { value: "", tag: ["join"] },
            tel: { value: "", tag: ["join"] },
        }*/
    },
    mutations: {
        updateID(state, value) {
            state.ID_stored = value;
        },
        updateViewmode(state, value) {
            state.viewmode_stored = value;
        },
        updateLabel(state, value) {
            state.label_stored = value;
        },
        updateRecord(state, value) {
            state.record_stored = value;
        },
        updateLogined(state, value) {
            state.logined_stored = value;
        },
        updateCommon(state, value) {
            state.common_stored = value;
        },
        updateUnit(state, value) {
            // console.log("updateUnit:", state.unit_stored)
            state.unit_stored = value;
        },
        set_options(state, data) {
            // 状態を変更する
            state.options = data;
            console.log("set_options")
        }

    },
    actions: {},
    modules: {}
})