//webアプリのバージョン番号、数字型のため長さは固定で
window.appVersion = "20240828002";
//window.API_URL = "https://dev.santaclaus-embassy.com/api/front/";
window.API_URL = "https://cms.santaclaus-embassy.com/api/front/";
//checkalways取得時に追加で取得する情報がある場合はページ初期化時に格納しておく
//window.gaID = "UA-42386303-1";
window.checkalways_includes = "";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import helper from './helpers/helper'
import record from './helpers/record'
import "es6-promise/auto";
import "fetch-polyfill";




import './assets/css/reset.css';
import './assets/css/app.scss';

Vue.config.productionTip = false
Vue.mixin(helper)
Vue.mixin(record)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')