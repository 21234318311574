<template>
  <div class="lostpass_comp">
      <!---<router-link @click.native="scrollToTop" to="/join">パスワードを忘れた方</router-link>-->
      <button @click="available=true">パスワードを忘れた方</button>

<div v-if="available" class="modal" @click.self="available=false">
    <div class="lostpass_window">
        <div v-if="!msg">
            パスワードの再発行を行います。<br>
            以下に、登録済みのメールアドレスを入力してください
            <p><input type="text" val="email" v-model="email" placeholder="例：onamae@your-domain.com"></p>
            <p class="errormsg" v-if="errormsg">{{errormsg}}</p>
            <p class="bns1"><button class="primary" @click="submit">パスワード再発行</button> <button @click ="available=false">キャンセル</button></p>
        </div>
        <div v-else>
            <div class="msg">{{msg}}</div>

            <p class="bns"><button @click ="clearwindow">閉じる</button></p>
            
        </div>
    </div>
</div>

  </div>
</template>

<script>
//import { mapState, mapMutations, mapActions } from "vuex";
//新規ログインの機能はコンポーネント化してポップアップで会員ログインできるようにする
export default {
  data: function () {
    return {
     available:false,
      errormsg: "",
      email:"",
      msg:""
    };
  },
  props:["value"],
  created:function(){

  },
  watch:{
    //token:function(){console.log("token changed")}
  },
  computed: {
    //...mapState(["token"]),
  },
  methods: {
      submit:function(){
          if(!this.email){
              this.errormsg="登録したメールアドレスを入力してください";
              return;
          }
          this.fetch("lostpass",{email:this.email}).then((data=>{
              this.msg=data.msg;
          }))
      },
      clearwindow:function(){

      this.available=false;
      this.email="";
      this.msg="";
      }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(129, 129, 129, 0.808);
    z-index: 999;
    .lostpass_window{
        text-align: left;
        background: #fff;
        padding: 4em 3em;
        border-radius: .8em;
    }
}
button{
    background: rgb(245, 245, 245);
    font-size: .7em;
    color: #000;
    margin: .2em 0;
    &.primary{
        background: rgb(131, 160, 104);
        color: #fff;
    }
}
input{
    width: 80%;
    max-width: 30em;
    margin: .7em 0;
}
.errormsg {
  background: #f3f2c2;
  border-radius: 0.5em;
  padding: 0.5em 0;
  font-size: .8em;
  margin: .2em 0;
}
.msg {
  border-radius: 0.5em;
  padding: 3em 0;
  font-size: .8em;
  text-align: center;
  white-space: pre-wrap;
}
.bns{
    text-align: center;
}
</style>