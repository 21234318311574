import { mapState } from "vuex";
export default ({
    created: function() {},
    watch: {
        record: {
            handler: function() {
                //console.log('this.recordが変化したためstoreとsessionに保存', JSON.stringify(this.record));
                //console.log('this.recordが変化したためstoreとsessionに保存');
                this.$store.commit('updateRecord', this.record);
                this.$store.state.record = this.record;
                this.setSession("record", this.record, true);
            },
            deep: true,
        }
    },
    computed: {
        viewmode: {
            get() {
                return this.$store.state.viewmode_stored;
            },
            set(value) {
                //TODO:注文変更画面でなぜか値が入るので回避
                if (typeof value == "undefined") return;
                //console.log("IDを変更した：" + value)
                this.$store.commit('updateViewmode', value);
            }
        },
        currentID: {
            get() {
                return this.$store.state.ID_stored;
            },
            set(value) {
                //TODO:注文変更画面でなぜか値が入るので回避
                if (typeof value == "undefined") return;
                console.log("IDを変更した：" + value)
                this.$store.commit('updateID', value);
            }
        },
        currentLabel: {
            get() {
                return this.$store.state.label_stored;
            },
            set(value) {
                //TODO:注文変更画面でなぜか値が入るので回避
                if (typeof value == "undefined") return;
                if (!/^[a-z0-9_]+$/i.test(value)) return;
                console.log("currentLabelを変更した：" + value)
                this.$store.commit('updateLabel', value);
            }
        },
        logined: {
            get() {
                return this.$store.state.logined_stored;
            },
            set(value) {
                this.$store.commit('updateLogined', value);
            }
        },
        common: {
            get() {
                return this.$store.state.common_stored;
            },
            set(value) {
                this.$store.commit('updateCommon', value);
            }
        },
        record: {
            get() {
                return this.$store.state.record_stored;
            },
            set(value) {
                this.$store.commit('updateRecord', value);
            }
        },
        /*
                unit: {
                    get() {
                        //console.log("unit stored:", this.$store.state.unit_stored)
                        return this.$store.state.unit_stored;
                    },
                    set(value) {
                        this.$store.commit('updateUnit', value);
                    }
                }*/
    },
    data: function() {
        return {
            ...mapState(["record_stored", "unit_stored"]),
        }
    }
})